import {
  AiEngineerJd,
  AIMlEngineerJd,
  AiMlEngineerPkJd,
  ClientSuccessManagerJd,
  ColdCallerJd,
  ColdCallingSpecialistJd,
  ContentWriterJd,
  DataScientistJd,
  DevOpsDeveloperJd,
  EmailMarketingLeadGenerationJd,
  FrontendInternJd,
  FullstackWebJd,
  LeadGenerationDescription,
  LinkedInLeadGenerationJd,
  MachineLearningJd,
  PythonDeveloperJd,
  SeniorContentStrategiesJd,
  SeniorDevOpsJd,
  SeniorFlutterJd,
  SeniorFullstackJd,
  SEOSEMJd,
  ShopifyDeveloperJd,
  UiUxDesignerJd,
  UnityGameDeveloperJd,
  UpworkBiddersJd,
  WebDeveloperJd,
  WordPressDeveloperJd,
} from "./JobDescriptions";

const JoinFormData = [
  // {
  //   id: 1,
  //   stack: "Senior Fullstack Engineer",
  //   skills: [
  //     "Swift",
  //     "HTML/CSS",
  //     "Vue.Js",
  //     "React.Js",
  //     "JavaScript",
  //     "Java",
  //     "Wordpress",
  //     "Flutter",
  //     "Android",
  //     "Node.Js",
  //     "PHP",
  //     "Bootstrap5",
  //     "React Native",
  //     "Rails",
  //     "Python",
  //     "Laravel",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 2,
  //   stack: "Mid-level Fullstack Engineer",
  //   skills: [
  //     "Swift",
  //     "HTML/CSS",
  //     "Vue.Js",
  //     "React.Js",
  //     "JavaScript",
  //     "Java",
  //     "Wordpress",
  //     "Flutter",
  //     "Android",
  //     "Node.Js",
  //     "PHP",
  //     "Bootstrap5",
  //     "React Native",
  //     "Rails",
  //     "Python",
  //     "Laravel",
  //   ],
  // },
  // {
  //   id: 3,
  //   stack: "Junior Fullstack Engineer",
  //   skills: [
  //     "Swift",
  //     "HTML/CSS",
  //     "Vue.Js",
  //     "React.Js",
  //     "JavaScript",
  //     "Java",
  //     "Wordpress",
  //     "Flutter",
  //     "Android",
  //     "Node.Js",
  //     "PHP",
  //     "Bootstrap5",
  //     "React Native",
  //     "Rails",
  //     "Python",
  //     "Laravel",
  //   ],
  // },
  // {
  //   id: 2,
  //   stack: "Senior Software Engineer",
  //   skills: [
  //     "Node.Js",
  //     "PHP",
  //     "Rails",
  //     "Laravel",
  //     "MySQL",
  //     "MongoDB",
  //     "C#",
  //     "AWS",
  //     "Swift",
  //     "Python",
  //     "Java",
  //     "Flutter",
  //     "Android",
  //     "Node.Js",
  //     "PHP",
  //     "Rails",
  //     "Laravel",
  //     "MySQL",
  //     "MongoDB",
  //     "Wordpress",
  //     "Bootstrap5",
  //     "React Native",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 3,
  //   stack: "Senior Backend Engineer",
  //   skills: [
  //     "C#",
  //     "AWS",
  //     "Swift",
  //     "Python",
  //     "Java",
  //     "Flutter",
  //     "Android",
  //     "Node.Js",
  //     "PHP",
  //     "Rails",
  //     "Laravel",
  //     "MySQL",
  //     "MongoDB",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 5,
  //   stack: "Mid-level Backend Engineer",
  //   skills: [
  //     "C#",
  //     "AWS",
  //     "Swift",
  //     "Python",
  //     "Java",
  //     "Flutter",
  //     "Android",
  //     "Node.Js",
  //     "PHP",
  //     "Rails",
  //     "Laravel",
  //     "MySQL",
  //     "MongoDB",
  //   ],
  // },
  // {
  //   id: 6,
  //   stack: "Junior Backend Engineer",
  //   skills: [
  //     "C#",
  //     "AWS",
  //     "Swift",
  //     "Python",
  //     "Java",
  //     "Flutter",
  //     "Android",
  //     "Node.Js",
  //     "PHP",
  //     "Rails",
  //     "Laravel",
  //     "MySQL",
  //     "MongoDB",
  //   ],
  // },
  // {
  //   id: 4,
  //   stack: "Senior Frontend Developer",
  //   skills: [
  //     "HTML/CSS",
  //     "Vue.Js",
  //     "React.Js",
  //     "JavaScript",
  //     "Wordpress",
  //     "Bootstrap5",
  //     "React Native",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 8,
  //   stack: "Mid-level Frontend Developer",
  //   skills: [
  //     "HTML/CSS",
  //     "Vue.Js",
  //     "React.Js",
  //     "JavaScript",
  //     "Wordpress",
  //     "Bootstrap5",
  //     "React Native",
  //   ],
  // },
  // {
  //   id: 9,
  //   stack: "Junior Frontend Developer",
  //   skills: [
  //     "HTML/CSS",
  //     "Vue.Js",
  //     "React.Js",
  //     "JavaScript",
  //     "Wordpress",
  //     "Bootstrap5",
  //     "React Native",
  //   ],
  // },
  // {
  //   id: 5,
  //   stack: "Senior CMS Developer",
  //   skills: [
  //     "HTML/CSS",
  //     "PHP",
  //     "JavaScript",
  //     "Elementor",
  //     "Divi",
  //     "Beaver",
  //     "SQL",
  //     "MySQL",
  //     "SEO",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 11,
  //   stack: "Mid-level WordPress Developer",
  //   skills: [
  //     "HTML/CSS",
  //     "PHP",
  //     "JavaScript",
  //     "Elementor",
  //     "Divi",
  //     "Beaver",
  //     "SQL",
  //     "MySQL",
  //     "SEO",
  //   ],
  // },
  // {
  //   id: 12,
  //   stack: "Junior WordPress Developer",
  //   skills: [
  //     "HTML/CSS",
  //     "PHP",
  //     "JavaScript",
  //     "Elementor",
  //     "Divi",
  //     "Beaver",
  //     "SQL",
  //     "MySQL",
  //     "SEO",
  //   ],
  // },
  // {
  //   id: 5,
  //   stack: "Senior Graphic Designer",
  //   skills: [
  //     "Adobe Photoshop",
  //     "InDesign",
  //     "Illustrator ",
  //     "UI/UX",
  //     "Figma",
  //     "Adobe XD",
  //     "Typography",
  //     "Branding",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 14,
  //   stack: "Mid-level Graphic Designer",
  //   skills: [
  //     "Photoshop",
  //     "InDesign",
  //     "Illustrator ",
  //     "UI,UX",
  //     "Figma",
  //     "XD",
  //     "Typography",
  //     "Branding",
  //   ],
  // },
  // {
  //   id: 15,
  //   stack: "Junior Graphic Designer",
  //   skills: [
  //     "Photoshop",
  //     "InDesign",
  //     "Illustrator ",
  //     "UI,UX",
  //     "Figma",
  //     "XD",
  //     "Typography",
  //     "Branding",
  //   ],
  // },
  // {
  //   id: 6,
  //   stack: "Senior Quality Assurance",
  //   skills: [
  //     "Functional Testing",
  //     "Quality Control",
  //     "QA testing",
  //     "Quality Control",
  //     "Non Functional Testing",
  //     "Automation Testing",
  //     "Selenium",
  //     "API Testing",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  {
    id: 6,
    stack: "Python Developer",
    skills: [
      "Web Development",
      "Desktop App Development",
      "Mobile App Development",
      "Game Development",
      "Flask",
      "Django",
      "Lamp",
      "AWS",
      "CI/CD",
      "S3 Bucket",
      "Lambda Functions",
      "DynamoDD",
      "MySQL",
      "PostgresSQl",
      "ECR",
      "ECS",
      "ML",
      "Git",
      "Git Bucket",
      "Data Analysis",
      "Visualization",
    ],
    jobType: "Remote",
    location: "Global",
    jobDes: PythonDeveloperJd,
  },
  // {
  //   id: 8,
  //   stack: "Django Developer",
  //   skills: [
  //     "Web Development",
  //     "API Development",
  //     "Backend Development",
  //     "Django",
  //     "Django REST Framework",
  //     "Python",
  //     "PostgreSQL",
  //     "MySQL",
  //     "AWS",
  //     "Docker",
  //     "Kubernetes",
  //     "CI/CD",
  //     "Git",
  //     "GitHub",
  //     "Heroku",
  //     "Nginx",
  //     "Celery",
  //     "Redis",
  //     "GraphQL",
  //     "Unit Testing",
  //     "Machine Learning",
  //     "Data Analysis",
  //     "Visualization",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 9,
  //   stack: "LAMP Developer",
  //   skills: [
  //     "Web Development",
  //     "Backend Development",
  //     "Linux",
  //     "Apache",
  //     "MySQL",
  //     "PHP",
  //     "Laravel",
  //     "CodeIgniter",
  //     "HTML",
  //     "CSS",
  //     "JavaScript",
  //     "jQuery",
  //     "Bootstrap",
  //     "Git",
  //     "GitLab",
  //     "AWS",
  //     "Docker",
  //     "Nginx",
  //     "cPanel",
  //     "Database Optimization",
  //     "API Integration",
  //     "Web Security",
  //     "SEO Optimization",
  //     "Performance Tuning",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 10,
  //   stack: "Flask Developer",
  //   skills: [
  //     "Web Development",
  //     "API Development",
  //     "Backend Development",
  //     "Python",
  //     "Flask",
  //     "Flask-RESTful",
  //     "PostgreSQL",
  //     "MySQL",
  //     "SQLite",
  //     "AWS",
  //     "Heroku",
  //     "Docker",
  //     "Nginx",
  //     "CI/CD",
  //     "Git",
  //     "GitHub",
  //     "Redis",
  //     "Celery",
  //     "Unit Testing",
  //     "GraphQL",
  //     "JWT Authentication",
  //     "OAuth",
  //     "WebSocket",
  //     "Data Analysis",
  //     "Machine Learning",
  //     "Visualization",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 11,
  //   stack: "Client Success Manager",
  //   skills: [
  //     "Project Managment",
  //     "SaaS Client Management",
  //     "Sales Experience",
  //     "CRM Client Management",
  //     "Quotes Creation",
  //     "Orders Creation",
  //     "Product Knowledge",
  //     "Development Experience",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 7,
  //   stack: "Senior DevOps Engineer",
  //   skills: [
  //     "AWS",
  //     "Azure",
  //     "Docker",
  //     "GCP",
  //     "CD/CI Pipelines",
  //     "Jenkins",
  //     "Git",
  //     "GitLab CI",
  //     "Embedded Sysytems",
  //     "Raspberry Pi",
  //   ],
  //   jobDes: SeniorDevOpsJd,
  // },
  // {
  //   id: 8,
  //   stack: "Senior Flutter developer",
  //   skills: [
  //     "iOS",
  //     "Android",
  //     "API Integration",
  //     "Swift",
  //     "Cocoa Touch",
  //     "Firebase",
  //     "MySQL",
  //     "JSON",
  //     "XML",
  //   ],
  //   jobDes: SeniorFlutterJd,
  // },
  // {
  //   id: 14,
  //   stack: "Senior SMM",
  //   skills: [
  //     "Content Creation",
  //     "Analytics Manager",
  //     "Copywriting",
  //     "Video Editing",
  //     "Social Media Strategy",
  //     "SEO Knowledge",
  //     "Branding",
  //     "Facebook Ads",
  //     "Instagram Ads",
  //     "Paid Campaign Management",
  //     "Audience Research",
  //     "Sprout Social",
  //     "Hootsuite",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 15,
  //   stack: "Email Marketing Specialist",
  //   skills: [
  //     "DotDigital",
  //     "MailChimp",
  //     "SendGrid",
  //     "Klaviyo",
  //     "HTML-based Templates ",
  //     "Adobe Creative Suite",
  //     "A/B testing",
  //     "CAN-SPAM",
  //     "GDPR",
  //     "SEO Knowledge",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 16,
  //   stack: "BD Executive",
  //   skills: [
  //     "Negotiation",
  //     "Interpersonal Skills",
  //     "Sales Strategy",
  //     "Lead Generation",
  //     "Market Research",
  //     "Networking",
  //     "Cold Calling",
  //     "CRM Proficiency",
  //     "Contract Management",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 17,
  //   stack: "Senior Content Writer",
  //   skills: [
  //     "CMS",
  //     "Basic HTML",
  //     "SEO ",
  //     "Copywriting",
  //     "Storytelling",
  //     "Keyword Research",
  //     "Headline Writing",
  //     "Marketing Knowledge",
  //     "Blogs Writing",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 9,
  //   stack: "Senior Content Strategist",
  //   skills: [
  //     "Content Planning",
  //     "Semrush",
  //     "Ahrefs",
  //     "Google Analytics",
  //     "SEO metrics",
  //     "Competitive Analysis",
  //     "CMS",
  //     "UX/UI",
  //     "Content Optimization",
  //     "Keyword Research",
  //   ],
  //   jobDes: SeniorContentStrategiesJd,
  // },
  // {
  //   id: 19,
  //   stack: "Senior Android Developer",
  //   skills: [
  //     "Android SDK",
  //     "Kotlin",
  //     "MVVM",
  //     "Dagger2",
  //     "JUnit",
  //     "Espresso",
  //     "Mockito",
  //     "Git",
  //     "RxJava",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 20,
  //   stack: "Senior iOS Developer",
  //   skills: [
  //     "Objective-C",
  //     "Swift",
  //     "Xcode",
  //     "Dagger2",
  //     "JUnit",
  //     "Espresso",
  //     "Mockito",
  //     "MVVM",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  {
    id: 10,
    stack: "AI & Machine Learning Engineer",
    skills: [
      "Python",
      "C++",
      "R",
      "Julia",
      "TensorFlow",
      "PyTorch",
      "Scikit-learn",
      "LightGBM",
      "OpenCV",
    ],
    jobType: "Remote",
    location: "Global",
    jobDes: AiMlEngineerPkJd,
  },
  // {
  //   id: 11,
  //   stack: "Data Scientist",
  //   skills: [
  //     "ML",
  //     "DL",
  //     "NLP",
  //     "Data Mining",
  //     "BI",
  //     "Python",
  //     "NumPy",
  //     "Keras",
  //     "Docker",
  //     "GCP",
  //     "Kubernetes",
  //     "AWS",
  //     "Azure",
  //   ],
  //   jobDes: DataScientistJd,
  // },
  // {
  //   id: 23,
  //   stack: "Senior Blockchain Developer",
  //   skills: [
  //     "dApps",
  //     "Smart Contracts",
  //     "NLP",
  //     "DLT",
  //     "Consensus Algorithms",
  //     "NFT Development",
  //     "Solidity",
  //     "Python",
  //     "C++",
  //     "DeFi",
  //     "Kubernetes",
  //     "Ethers.js",
  //     "Truffle",
  //     "Ethereum",
  //     "Solana",
  //     "Avalanche",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 24,
  //   stack: "Senior Mobile Developer",
  //   skills: [
  //     "iOS",
  //     "Android",
  //     "API Integration",
  //     "Swift",
  //     "Cocoa Touch",
  //     "Firebase",
  //     "MySQL",
  //     "JSON",
  //     "XML",
  //   ],
  //   jobDes: SeniorFullstackJd,
  // },
  // {
  //   id: 12,
  //   stack: "AI Engineer",
  //   skills: [
  //     "Python",
  //     "C++",
  //     "R",
  //     "Julia",
  //     "TensorFlow",
  //     "PyTorch",
  //     "Scikit-learn",
  //     "LightGBM",
  //     "OpenCV",
  //   ],
  //   jobDes: AiEngineerJd,
  // },
  // {
  //   id: 15,
  //   stack: "Web (Full Stack developer)",
  //   skills: [
  //     "HTML",
  //     "CSS",
  //     "Javascript",
  //     "ReactJs",
  //     "Redux",
  //     "RTK",
  //     "Bootstrap",
  //     "Tailwind",
  //     "MUI",
  //     "Shadcn",
  //     "Antd",
  //     "MySql",
  //     "GraphQl",
  //     "PostgresSql",
  //     "MongoDb",
  //     "NodeJs",
  //     "Typescript",
  //     "Laravel",
  //   ],
  //   jobDes: FullstackWebJd,
  // },
  // {
  //   id: 13,
  //   stack: "Sales Executive-Cold Calling Specialist",
  //   skills: [
  //     "iOS",
  //     "Android",
  //     "API Integration",
  //     "Swift",
  //     "Cocoa Touch",
  //     "Firebase",
  //     "MySQL",
  //     "JSON",
  //     "XML",
  //   ],
  //   jobDes: ColdCallingSpecialistJd,
  // },
  // {
  //   id: 14,
  //   stack: "Front-End Development Intern",
  //   skills: [
  //     "HTML",
  //     "CSS",
  //     "Javascript",
  //     "ReactJs",
  //     "Redux",
  //     "RTK",
  //     "Bootstrap",
  //     "Tailwind",
  //     "MUI",
  //     "Shadcn",
  //     "Antd",
  //   ],
  //   jobDes: FrontendInternJd,
  // },

  // {
  //   id: 16,
  //   stack: "Web Developer(WordPress, Shopify, Wix, and Webflow)",
  //   skills: [
  //     "HTML",
  //     "CSS",
  //     "Javascript",
  //     "Wix",
  //     "Webflow",
  //     "Shopify",
  //     "WordPress",
  //     "Elementor",
  //     "Divi",
  //     "Beaver",
  //     "SQL",
  //     "MySQL",
  //     "SEO",
  //   ],
  //   jobDes: WebDeveloperJd,
  // },
  {
    id: 17,
    stack: "Unity Game App Developers",
    skills: [
      "Unity",
      "C#",
      "Game Physics",
      "AR/VR Development",
      "Unity 2D/3D",
      "Photon Networking",
      "AI for Games",
      "Shader Programming",
      "Mobile Game Optimization",
      "Multiplayer Game Development",
      "Gameplay Scripting",
      "Animation",
      "Game UI/UX",
    ],
    jobType: "Remote",
    location: "Global",
    jobDes: UnityGameDeveloperJd, // Replace this with the actual Unity Developer job description
  },
  {
    id: 18,
    stack: "UI/UX Designers",
    skills: [
      "User Research",
      "Wireframing",
      "Prototyping",
      "Figma",
      "Adobe XD",
      "Sketch",
      "User Experience (UX)",
      "User Interface (UI)",
      "Responsive Design",
      "Interaction Design",
      "Design Thinking",
      "Usability Testing",
      "Typography",
      "Color Theory",
      "Visual Design",
    ],
    jobType: "Remote",
    location: "Global",
    jobDes: UiUxDesignerJd, // Replace this with the actual UI/UX Designer job description
  },
  {
    id: 19,
    stack: "Shopify Developers",
    skills: [
      "Shopify Theme Development",
      "Liquid Templating",
      "HTML",
      "CSS",
      "JavaScript",
      "Shopify API",
      "GraphQL",
      "REST API",
      "Headless Shopify",
      "Shopify App Development",
      "Shopify Plus",
      "Custom Shopify Apps",
      "Payment Gateway Integration",
      "SEO Optimization",
      "Performance Optimization",
    ],
    jobType: "Remote",
    location: "Global",
    jobDes: ShopifyDeveloperJd, // Replace this with the actual Shopify Developer job description
  },
  {
    id: 20,
    stack: "WordPress Developers",
    skills: [
      "WordPress Theme Development",
      "WordPress Plugin Development",
      "PHP",
      "HTML",
      "CSS",
      "JavaScript",
      "Elementor",
      "Divi",
      "Beaver Builder",
      "WooCommerce",
      "REST API",
      "Custom Post Types",
      "SEO Optimization",
      "Security Hardening",
      "Performance Optimization",
    ],
    jobType: "Remote",
    location: "Global",
    jobDes: WordPressDeveloperJd, // Replace this with the actual WordPress Developer job description
  },
  {
    id: 21,
    stack: "DevOps Developer",
    skills: [
      "CI/CD Pipelines",
      "Docker",
      "Kubernetes",
      "Terraform",
      "Ansible",
      "AWS",
      "Azure",
      "Google Cloud Platform (GCP)",
      "Linux Administration",
      "Shell Scripting",
      "Git & Version Control",
      "Monitoring & Logging",
      "Jenkins",
      "Cloud Security",
      "Infrastructure as Code (IaC)",
    ],
    jobType: "Remote",
    location: "Global",
    jobDes: DevOpsDeveloperJd, // Replace this with the actual DevOps Developer job description
  },
  {
    id: 22,
    stack: "Upwork Bidders",
    skills: [
      "Proposal Writing",
      "Client Communication",
      "Lead Generation",
      "Market Research",
      "Negotiation Skills",
      "Upwork Profile Optimization",
      "Time Management",
      "Project Management",
      "Cold Outreach",
      "CRM Tools",
      "Freelance Marketplace Strategies",
      "Business Development",
      "English Proficiency",
      "Sales & Marketing",
      "Competitive Analysis",
    ],
    jobType: "Onsite",
    jobDes: UpworkBiddersJd, // Replace this with the actual Upwork Bidders job description
  },
  {
    id: 23,
    stack: "SEO/SEM",
    skills: [
      "Search Engine Optimization (SEO)",
      "Search Engine Marketing (SEM)",
      "Google Ads",
      "Keyword Research",
      "On-Page SEO",
      "Off-Page SEO",
      "Technical SEO",
      "Content Marketing",
      "Link Building",
      "Google Analytics",
      "Google Search Console",
      "Pay-Per-Click (PPC)",
      "Conversion Rate Optimization (CRO)",
      "Competitor Analysis",
      "Social Media Advertising",
    ],
    jobType: "Onsite",
    jobDes: SEOSEMJd, // Replace this with the actual SEO/SEM job description
  },
  {
    id: 24,
    stack: "Content Writer",
    skills: [
      "SEO Writing",
      "Copywriting",
      "Blog Writing",
      "Content Strategy",
      "Technical Writing",
      "Creative Writing",
      "Editing & Proofreading",
      "Research Skills",
      "Social Media Content",
      "Email Marketing",
      "Keyword Optimization",
      "Storytelling",
      "WordPress Content Management",
      "AP Style & Grammar",
      "Content Marketing",
    ],
    jobType: "Onsite",
    jobDes: ContentWriterJd, // Replace this with the actual Content Writers job description
  },
  {
    id: 25,
    stack: "LinkedIn Lead Generation",
    skills: [
      "Lead Generation",
      "LinkedIn Sales Navigator",
      "B2B Marketing",
      "Cold Outreach",
      "Networking",
      "Profile Optimization",
      "Connection Building",
      "CRM Management",
      "Market Research",
      "Personalized Messaging",
      "Content Strategy",
      "Email Outreach",
      "Data Scraping",
      "Analytics & Reporting",
      "Social Selling",
    ],
    jobType: "Onsite",
    jobDes: LinkedInLeadGenerationJd, // Replace this with the actual LinkedIn Lead Generation job description
  },
  {
    id: 26,
    stack: "Cold Caller",
    skills: [
      "Cold Calling",
      "Lead Generation",
      "Sales Pitching",
      "Customer Relationship Management (CRM)",
      "Active Listening",
      "Objection Handling",
      "Negotiation Skills",
      "Outbound Sales",
      "Script Development",
      "Follow-Up Strategies",
      "Market Research",
      "Appointment Setting",
      "Time Management",
      "Persuasive Communication",
      "Telemarketing",
    ],
    jobType: "Onsite",
    jobDes: ColdCallerJd, // Replace this with the actual Cold Callers job description
  },
  {
    id: 27,
    stack: "Email Marketing Lead Generation",
    skills: [
      "Email Campaign Management",
      "Lead Generation",
      "Cold Email Outreach",
      "Email Automation",
      "Personalized Email Writing",
      "A/B Testing",
      "CRM Management",
      "Copywriting",
      "Subject Line Optimization",
      "Deliverability Optimization",
      "Drip Campaigns",
      "Segmentation & Targeting",
      "Analytics & Reporting",
      "Spam Compliance (CAN-SPAM, GDPR)",
      "Conversion Rate Optimization",
    ],
    jobType: "Onsite",
    jobDes: EmailMarketingLeadGenerationJd, // Replace this with the actual Email Marketing Lead Generation job description
  },
  {
    id: 28,
    stack: "Lead Generation Specialist (USA Remote – W2 Only)",
    skills: [
      "Cold Calling",
      "Lead Generation",
      "Sales Pitching",
      "Customer Relationship Management (CRM)",
      "Active Listening",
      "Objection Handling",
      "Negotiation Skills",
      "Outbound Sales",
      "Script Development",
      "Follow-Up Strategies",
      "Market Research",
      "Appointment Setting",
      "Time Management",
      "Persuasive Communication",
      "Telemarketing",
    ],
    jobType: "Remote",
    location: "USA",
    jobDes: LeadGenerationDescription, // Replace this with the actual Email Marketing Lead Generation job description
  },
  {
    id: 29,
    stack: "AI/ML Engineers (USA Remote – W2 Only)",
    skills: [
      "Machine Learning",
      "Deep Learning",
      "Natural Language Processing (NLP)",
      "Computer Vision",
      "Neural Networks",
      "Python",
      "TensorFlow",
      "PyTorch",
      "Scikit-learn",
      "Data Preprocessing",
      "Big Data Technologies",
      "Model Deployment",
      "Cloud Computing (AWS, GCP, Azure)",
      "MLOps",
      "Artificial Intelligence",
    ],
    jobType: "Remote",
    location: "USA",
    jobDes: AIMlEngineerJd, // Replace this with the actual AI/ML Engineers job description
  },
  {
    id: 30,
    stack: "DevOps Engineer (USA Remote – W2 Only)",
    skills: [
      "CI/CD Pipelines",
      "Docker",
      "Kubernetes",
      "Terraform",
      "Ansible",
      "AWS",
      "Azure",
      "Google Cloud Platform (GCP)",
      "Linux Administration",
      "Shell Scripting",
      "Git & Version Control",
      "Monitoring & Logging",
      "Jenkins",
      "Cloud Security",
      "Infrastructure as Code (IaC)",
    ],
    jobType: "Remote",
    location: "USA",
    jobDes: SeniorDevOpsJd, // Replace this with the actual AI/ML Engineers job description
  },
  {
    id: 31,
    stack: "Client Success Manager (USA Remote – W2 Only)",
    skills: [
      "Project Managment",
      "SaaS Client Management",
      "Sales Experience",
      "CRM Client Management",
      "Quotes Creation",
      "Orders Creation",
      "Product Knowledge",
      "Development Experience",
    ],
    jobType: "Remote",
    location: "USA",
    jobDes: ClientSuccessManagerJd, // Replace this with the actual AI/ML Engineers job description
  },
];

export default JoinFormData;
