export const SeniorFullstackJd = [
  "Interpreting client specifications and identifying project requirements.",
  "Create/help/assist in making sprints, milestones, and deliverables.",
  "Communicating effectively with clients and team.",
  "Establishing strong professional relationships with clients and other professionals.",
  "Supervising junior engineers, analyzing areas of weakness, and scheduling training sessions accordingly.",
  "Develop software solutions by studying information needs; conferring with users; studying systems flow, data usage, and work processes; investigating problem areas; following the software development lifecycle.",
  "Evaluate the performance of the team and suggest improvements for their growth.",
  "Determine operational feasibility by evaluating analysis, problem definition, requirements, solution development, and proposed solutions.",
  "Document and demonstrate solutions by developing documentation, flowcharts, layouts, diagrams, charts, code comments, and clear code.",
  "Prepare and install solutions by determining and designing system specifications, standards, and programming.",
  "Improve operations by conducting systems analysis; recommending changes in policies and procedures.",
  "Update job knowledge by studying state-of-the-art development tools, programming techniques, and computing equipment; participating in educational opportunities; reading professional publications; maintaining personal networks; participating in professional organizations.",
  "Provide information by collecting, analyzing, and summarizing development and service issues.",
  "Protect operations by keeping information confidential.",
  "Accomplish engineering and organization mission by completing related results as needed.",
  "Support and develop software engineers by providing advice, coaching, and educational opportunities.",
  "Mentor junior and mid-level engineers, and manage their KPIs.",
  "Collaborate with the team to brainstorm and create new products.",
  "Grow engineering teams by interviewing, recruiting, and hiring.",
  "Make informed decisions quickly and take ownership of services and applications at scale.",
  "Work collaboratively with others to achieve goals.",
  "Be a persistent, creative problem-solver.",
  "Stay on the leading edge of development practices.",
  "Be passionate about great technologies, especially open source.",
  "Understand business needs and know how to create the tools to manage them.",
];

export const SeniorFlutterJd = [
  "Interpreting client specifications and identifying project requirements.",
  "Create/help/assist in making sprints, milestones and deliverables.",
  "Communicating effectively with clients and team.",
  "Develop and maintain high-quality, production-ready mobile applications using the Flutter framework.",
  "Collaborate with cross-functional teams to define, design, and ship new features.",
  "Ensure the performance, quality, and responsiveness of applications.",
  "Identify and troubleshoot issues, bottlenecks, and bugs, and provide solutions.",
  "Possess a strong understanding of Dart and proficiency in at least one object-oriented language such as Swift or Kotlin.",
  "Stay up-to-date with the latest industry trends and technologies.",
  "Participate in code and design reviews to maintain code quality standards.",
  "Continuously research and evaluate new Flutter packages and plugins for integration.",
  "Provide technical guidance and support to junior developers, if applicable.",
  "Apply coding best practices and adhere to SOLID principles and CLEAN architecture concepts.",
  "Create complex, scalable, and maintainable mobile apps with a deep understanding of various architectural patterns (MVC, MVP) and their advantages and limitations.",
  "Able to write clean and effective unit tests, widget tests, and end-to-end (e2e) tests for robust application testing.",
  "Demonstrate a clear understanding of common data structures, their space/time complexity, and the ability to select the appropriate data structure for a given scenario.",
  "Collaborate effectively within an agile development environment, participating in sprints, code reviews, and pair programming sessions.",
  "Showcase strong communication skills, take a proactive role within the team, and be open to mentoring junior developers.",
  "Exhibit an interest in giving tech talks and sharing insights gained from exploring the ever-evolving tech landscape.",
];

export const SeniorContentStrategiesJd = [
  "Develop and execute content strategies for websites, social media, emails, and branding materials.",
  "Align content strategies with the company's objectives and analyze trends to enhance visibility and engagement.",
  "Utilize various platforms to generate leads and drive business growth.",
  "Continuously evaluate the effectiveness of existing content across all platforms.",
  "Identify areas for improvement and implement innovative content solutions to enhance engagement and brand loyalty.",
  "Collaborate with marketing and design teams to ensure all content is on-brand and aligns with the company’s identity and goals.",
  "Strengthen brand presence through consistent and compelling content narratives across all channels.",
  "Write and produce engaging blogs, website content, social media posts, emails, and other digital content.",
  "Utilize SEO best practices to increase content reach and visibility.",
  "Monitor, track, and report on feedback and online reviews from audiences.",
  "Use analytics to gauge the success of content strategies and make data-driven decisions for future content.",
  "Stay up-to-date with industry trends and content best practices to keep the brand at the forefront of digital marketing innovations.",
  "Integrate new content formats and distribution methods to expand reach.",
  "Work closely with the digital marketing team and other departments to brainstorm new ideas.",
  "Optimize content delivery and maintain a cohesive brand voice across all channels.",
];

export const SeniorDevOpsJd = [
  "We are seeking a highly skilled DevOps Engineer with 4+ years of experience in cloud infrastructure management, CI/CD pipelines, automation, and system reliability.",
  "The ideal candidate should have expertise in AWS, Azure, or GCP, along with strong scripting and infrastructure-as-code (IaC) skills to ensure seamless deployment and system scalability.",

  // Responsibilities
  "Design, implement, and manage CI/CD pipelines for seamless software deployment.",
  "Automate infrastructure provisioning, scaling, and monitoring using Terraform, Ansible, or CloudFormation.",
  "Manage and optimize cloud environments (AWS, GCP, Azure) for high availability and performance.",
  "Implement and maintain containerization solutions (Docker, Kubernetes, Helm).",
  "Monitor system performance, security, and cost optimization through logging and observability tools (Prometheus, Grafana, ELK Stack).",
  "Collaborate with development and operations teams to enhance system reliability and efficiency.",
  "Troubleshoot and resolve infrastructure and deployment issues proactively.",
  "Ensure security best practices in cloud environments, networking, and CI/CD pipelines.",

  // Requirements
  "4+ years of experience in DevOps, cloud engineering, or site reliability engineering (SRE).",
  "Hands-on experience with AWS, Azure, or Google Cloud Platform (GCP).",
  "Strong knowledge of CI/CD tools (Jenkins, GitLab CI, GitHub Actions, ArgoCD, CircleCI).",
  "Proficiency in Terraform, Ansible, or CloudFormation for infrastructure automation.",
  "Experience with Docker, Kubernetes, Helm, and microservices architectures.",
  "Knowledge of networking, security best practices, and cloud cost optimization.",
  "Familiarity with scripting languages (Bash, Python, Go) for automation tasks.",
  "Strong problem-solving skills and ability to work in a fast-paced environment.",
  "Must have valid W2 work authorization in the USA (No C2C, No Visa Sponsorship).",
];

export const ColdCallingSpecialistJd = [
  "We are seeking a dynamic and results-driven Sales Executive with expertise in cold calling to join our team.",
  "The ideal candidate will have a proven track record in generating leads, building strong client relationships, and closing sales in a fast-paced environment.",
  "Conduct outbound cold calls to prospective clients to introduce products/services.",
  "Identify and qualify sales opportunities through effective communication and follow-up.",
  "Maintain and update the CRM system with accurate and timely information.",
  "Build rapport and establish relationships with potential customers.",
  "Present and explain product features and benefits tailored to client needs.",
  "Meet and exceed monthly sales targets and key performance indicators (KPIs).",
  "Collaborate with the sales and marketing teams to develop strategies for lead generation.",
  "Provide feedback on customer needs and market trends to improve offerings.",
  "Proven experience in cold calling and outbound sales (minimum 2-4 years).",
  "Excellent verbal and written communication skills.",
  "Strong persuasive and negotiation abilities.",
  "Ability to handle objections and turn prospects into customers.",
  "Proficient in CRM software and Microsoft Office Suite.",
  "Goal-oriented with a positive attitude and strong work ethic.",
  "Bachelor’s degree in Business, Marketing, or related field (preferred).",
];

export const DataScientistJd = [
  "We are seeking an experienced and motivated Data Scientist to join our dynamic team.",
  "The ideal candidate will have a strong background in data analysis, machine learning, and statistical modeling, with the ability to translate business challenges into data-driven solutions.",
  "Design, develop, and implement advanced analytics and machine learning models to solve complex business problems.",
  "Analyze large datasets to extract actionable insights and identify patterns and trends.",
  "Collaborate with cross-functional teams, including business, engineering, and product, to understand requirements and deliver data solutions.",
  "Build and maintain scalable data pipelines to automate data collection, cleaning, and preparation processes.",
  "Present findings and recommendations to stakeholders using visualizations and clear communication.",
  "Stay up-to-date with the latest industry trends and advancements in data science and machine learning.",
  "Bachelor’s or Master’s degree in Computer Science, Data Science, Statistics, Mathematics, or a related field.",
  "4+ years of professional experience in data science or a related role.",
  "Proficiency in programming languages such as Python, R, or Scala.",
  "Strong experience with machine learning frameworks like TensorFlow, PyTorch, or scikit-learn.",
  "Hands-on experience with SQL and NoSQL databases.",
  "Proficiency in data visualization tools like Tableau, Power BI, or matplotlib.",
  "Experience with cloud platforms such as AWS, GCP, or Azure is a plus.",
  "Excellent problem-solving and analytical skills.",
  "Strong communication and presentation skills.",
];

export const MachineLearningJd = [
  "Interpreting client specifications and identifying project requirements.",
  "Create/help/assist in making sprints, milestones, and deliverables.",
  "Communicating effectively with clients and team.",
  "Design, develop, and deploy machine learning models to solve business challenges and enhance products and services.",
  "Optimize and fine-tune algorithms for scalability, performance, and accuracy.",
  "Build and maintain robust data pipelines to support machine learning workflows.",
  "Work closely with data scientists, data engineers, and product teams to implement and monitor ML solutions in production environments.",
  "Evaluate and select appropriate machine learning frameworks, tools, and technologies.",
  "Conduct experiments and evaluate the performance of models using metrics and benchmarks.",
  "Stay updated with the latest advancements in machine learning and AI technologies to recommend and implement innovative solutions.",
  "Document and present technical solutions and findings to stakeholders in a clear and concise manner.",
  "Proficiency in programming languages like Python, Java, or C++.",
  "Strong experience with ML libraries and frameworks such as TensorFlow, PyTorch, scikit-learn, or Keras.",
  "Proficient in using SQL and NoSQL databases for data handling.",
  "Familiarity with cloud platforms like AWS, GCP, or Azure for deploying ML models.",
  "Experience with version control systems (e.g., Git) and ML workflow tools (e.g., MLflow, Kubeflow).",
  "Knowledge of MLOps principles, including model monitoring and CI/CD for ML pipelines.",
  "Strong problem-solving skills and a solid understanding of statistical methods and data preprocessing techniques.",
];

export const AiEngineerJd = [
  "Interpreting client specifications and identifying project requirements.",
  "Create/help/assist in making sprints, milestones, and deliverables.",
  "Communicating effectively with clients and team.",
  "Design, develop, and deploy AI models and algorithms to solve business challenges.",
  "Work with cross-functional teams to identify use cases and translate business requirements into AI solutions.",
  "Optimize and fine-tune AI models for performance, scalability, and accuracy.",
  "Build and maintain data pipelines for training, validation, and deployment of AI models.",
  "Conduct research to stay abreast of the latest advancements in AI and apply them to practical use cases.",
  "Collaborate with software engineers to integrate AI solutions into production systems.",
  "Implement monitoring and evaluation processes to ensure AI models perform effectively in real-world conditions.",
  "Document AI workflows, processes, and best practices.",
  "Proficiency in programming languages such as Python, R, or Java.",
  "Strong expertise in machine learning frameworks like TensorFlow, PyTorch, or scikit-learn.",
  "Hands-on experience with deep learning architectures such as CNNs, RNNs, GANs, or transformers.",
  "Familiarity with natural language processing (NLP) and computer vision techniques.",
  "Experience with cloud-based AI services (e.g., AWS SageMaker, Google AI Platform, Azure ML).",
  "Knowledge of big data frameworks like Spark, Hadoop, or Kafka is a plus.",
  "Strong understanding of statistical and mathematical foundations for AI.",
  "Excellent problem-solving and analytical skills.",
];

export const FrontendInternJd = [
  "We are seeking a motivated Front-End Development Intern to join our team and support the development of engaging and user-friendly web interfaces.",
  "The ideal candidate should have a basic understanding or prior exposure to WordPress, Shopify, and similar front-end stacks or technologies.",
  "This internship provides an excellent opportunity to contribute to real-world projects while gaining hands-on experience in front-end development.",
  "Assist in the design and development of responsive and interactive user interfaces for websites and web applications.",
  "Collaborate with the design and development teams to implement features using HTML, CSS, and JavaScript.",
  "Work with platforms like WordPress and Shopify to customize themes, plugins, and layouts.",
  "Conduct testing and debugging to ensure cross-browser compatibility and responsiveness.",
  "Learn and apply best practices for front-end coding, optimization, and performance.",
  "Support the maintenance and updates of existing websites and applications.",
  "Basic knowledge of front-end technologies, including HTML, CSS, and JavaScript.",
  "Prior exposure to WordPress, Shopify, or similar CMS platforms is a plus.",
  "Familiarity with responsive design principles and tools.",
  "Enthusiasm for learning new technologies and improving development skills.",
  "Strong attention to detail and problem-solving abilities.",
  "Ability to work collaboratively in a team environment.",
];

export const FullstackWebJd = [
  "Interpreting client specifications and identifying project requirements.",
  "Create/help/assist in making sprints, milestones, and deliverables.",
  "Communicating effectively with clients and team.",
  "Design, develop, and maintain fully functional web applications, ensuring seamless integration between front-end and back-end systems.",
  "Collaborate with UI/UX designers to translate wireframes and mockups into responsive and user-friendly interfaces.",
  "Build and maintain RESTful APIs and microservices to support application functionality.",
  "Write clean, maintainable, and well-documented code following best practices.",
  "Optimize application performance, troubleshoot bugs, and resolve issues promptly.",
  "Ensure security and data protection measures are in place for web applications.",
  "Work with DevOps to deploy, monitor, and maintain applications in production environments.",
  "Stay up-to-date with the latest trends and technologies in web development.",
  "Proficiency in front-end technologies: HTML, CSS, JavaScript, and modern frameworks like React, Angular, or Vue.js.",
  "Strong back-end skills in Node.js, Python, Ruby, Java, or similar languages.",
  "Experience with relational and non-relational databases such as MySQL, PostgreSQL, MongoDB, or Firebase.",
  "Hands-on experience with version control tools (e.g., Git).",
  "Familiarity with CI/CD pipelines and containerization tools like Docker or Kubernetes.",
  "Strong understanding of web security principles and authentication protocols (OAuth, JWT).",
  "Excellent problem-solving skills and attention to detail.",
];

export const WebDeveloperJd = [
  "WordPress: Customize themes, manage plugins, and perform basic development to build and maintain WordPress websites.",
  "Shopify: Set up storefronts, customize themes, and utilize Liquid templating to create dynamic and functional e-commerce stores.",
  "Wix and Webflow: Design and manage responsive websites using these platforms, ensuring optimal performance and user experience.",
  "HTML & CSS: Develop and maintain clean, efficient, and scalable front-end code for various web projects.",
  "Cross-Platform Expertise: Work with other front-end technologies or tools relevant to web development as required.",
  "Collaborate with designers, content creators, and project managers to deliver high-quality web solutions.",
  "Optimize websites for speed, SEO, and performance across all platforms.",
  "Troubleshoot and resolve technical issues related to website functionality or integrations.",
  "Stay up-to-date with emerging trends and best practices in web development and design.",
  "2+ years of professional experience in web development.",
  "Proficiency in WordPress theme customization, plugin management, and basic PHP development.",
  "Experience with Shopify storefront setup, theme customization, and Liquid templating.",
  "Familiarity with Wix and Webflow platforms for designing and managing responsive websites.",
  "Strong foundational knowledge of HTML, CSS, and responsive design principles.",
  "Understanding of cross-browser compatibility and web accessibility standards.",
  "Knowledge of SEO and website performance optimization techniques.",
  "Strong problem-solving and debugging skills.",
];

export const ClientSuccessManagerJd = [
  "We are looking for a proactive and customer-focused Client Success Manager with 4+ years of experience in customer relationship management, account management, and client retention.",
  "The ideal candidate will be responsible for ensuring long-term client satisfaction, driving product adoption, and maximizing customer value.",

  // Responsibilities
  "Act as the primary point of contact for clients, ensuring their needs are met and expectations exceeded.",
  "Build and maintain strong, long-term relationships with key stakeholders.",
  "Drive customer retention and renewals by delivering exceptional service and value.",
  "Work closely with sales, support, and product teams to ensure a seamless client experience.",
  "Identify upsell and cross-sell opportunities to maximize customer success and revenue growth.",
  "Conduct regular check-ins, QBRs (Quarterly Business Reviews), and performance reviews with clients.",
  "Monitor client health metrics and proactively address potential risks.",
  "Develop and execute customer success strategies to improve engagement and satisfaction.",
  "Assist in onboarding and training clients on company products/services.",

  // Requirements
  "4+ years of experience in customer success, account management, or client relationship management.",
  "Proven track record of driving customer satisfaction, retention, and growth.",
  "Strong communication, problem-solving, and negotiation skills.",
  "Experience with CRM software (Salesforce, HubSpot, Zoho, etc.).",
  "Ability to analyze customer data and feedback to optimize engagement.",
  "Experience working with SaaS, B2B, or technology-based solutions is a plus.",
  "Must have valid W2 work authorization in the USA (No C2C, No Visa Sponsorship).",
];

export const AIMlEngineerJd = [
  "We are seeking a highly skilled AI/ML Engineer with 4+ years of experience in machine learning model development, AI-driven solutions, and deep learning frameworks.",
  "The ideal candidate will have a strong background in Python, TensorFlow, PyTorch, and cloud-based AI solutions to build scalable and high-performance AI applications.",

  // Responsibilities
  "Design, develop, and deploy machine learning models and AI-driven applications.",
  "Build, train, and optimize deep learning and natural language processing (NLP) models.",
  "Work with large datasets, perform data preprocessing, feature engineering, and model evaluation.",
  "Implement and integrate AI/ML models into cloud environments (AWS, GCP, or Azure).",
  "Collaborate with data scientists, software engineers, and product teams to deliver AI-powered solutions.",
  "Conduct A/B testing, model tuning, and hyperparameter optimization for improved performance.",
  "Develop APIs and pipelines to deploy ML models into production environments.",
  "Stay updated with the latest AI/ML trends, research papers, and best practices.",

  // Requirements
  "4+ years of experience in AI/ML engineering, data science, or related fields.",
  "Strong programming skills in Python, TensorFlow, PyTorch, Scikit-learn.",
  "Experience with NLP, computer vision, reinforcement learning, or deep learning.",
  "Expertise in cloud-based AI/ML services (AWS SageMaker, GCP AI, Azure ML).",
  "Proficiency in SQL, NoSQL, and big data frameworks (Spark, Hadoop) is a plus.",
  "Familiarity with MLOps, CI/CD pipelines, and model deployment.",
  "Strong problem-solving skills and ability to work in an agile development environment.",
  "Must have valid W2 work authorization in the USA (No C2C, No Visa Sponsorship).",
];

export const LeadGenerationDescription = [
  "We are seeking a highly motivated Lead Generation Specialist with at least 4 years of experience in B2B lead generation, prospecting, and outreach.",
  "The ideal candidate should have a proven track record of identifying and qualifying high-quality leads using various digital and outbound strategies to drive business growth.",

  // Responsibilities
  "Identify and generate high-quality B2B leads through cold calling, email campaigns, LinkedIn outreach, and other lead gen techniques.",
  "Research and build targeted prospect lists using data enrichment tools and CRM software.",
  "Develop and execute personalized outreach strategies to engage decision-makers.",
  "Qualify leads based on company criteria and schedule sales appointments.",
  "Track and manage leads through CRM platforms (Salesforce, HubSpot, Zoho, etc.).",
  "Collaborate with the sales and marketing teams to optimize lead conversion.",
  "Analyze lead generation metrics and optimize outreach strategies.",
  "Stay updated with industry trends and competitor activities.",

  // Requirements
  "4+ years of experience in lead generation, B2B sales, or demand generation.",
  "Strong expertise in LinkedIn Sales Navigator, cold email outreach, and CRM tools.",
  "Experience with email automation platforms (Mailchimp, Outreach.io, Lemlist, etc.).",
  "Excellent communication, persuasion, and negotiation skills.",
  "Proven ability to meet and exceed lead generation targets.",
  "Experience with multi-channel outreach (LinkedIn, email, phone calls, etc.).",
  "Must have valid W2 work authorization in the USA (No C2C, No Visa Sponsorship).",
];

export const EmailMarketingLeadGenerationJd = [
  "We are looking for a results-driven Email Marketing Lead Generation Specialist with at least 4 years of experience in B2B email outreach, lead nurturing, and conversion optimization.",
  "The ideal candidate should have expertise in email automation, A/B testing, cold outreach, and CRM management to generate high-quality leads and drive business growth.",

  // Responsibilities
  "Develop and execute targeted email marketing campaigns to generate leads and nurture prospects.",
  "Research and build highly targeted B2B prospect lists using data enrichment tools.",
  "Craft compelling email sequences, subject lines, and personalized messaging to improve engagement.",
  "Utilize email automation platforms (e.g., Mailchimp, HubSpot, ActiveCampaign, Reply.io, Lemlist, etc.) to manage campaigns.",
  "Conduct A/B testing to optimize open rates, CTRs, and conversions.",
  "Monitor email deliverability, spam rates, and sender reputation to ensure high inbox placement.",
  "Track and analyze campaign performance metrics and report key insights to the team.",
  "Collaborate with sales and marketing teams to align lead generation efforts with business goals.",
  "Stay updated with email marketing trends, compliance regulations (GDPR, CAN-SPAM), and best practices.",

  // Requirements
  "4+ years of proven experience in email marketing, lead generation, or B2B outreach.",
  "Strong expertise in email automation platforms and CRM tools (HubSpot, Salesforce, Zoho, etc.).",
  "Excellent copywriting and personalization skills for high-converting email campaigns.",
  "Experience in segmentation, audience targeting, and behavioral triggers.",
  "Understanding of cold email outreach strategies and best practices.",
  "Data-driven mindset with the ability to analyze KPIs, ROI, and conversion metrics.",
  "Familiarity with email compliance laws (GDPR, CAN-SPAM, CASL).",
  "Experience with LinkedIn outreach and multi-channel lead generation is a plus."
];

export const LinkedInLeadGenerationJd = [
  "We are looking for a LinkedIn Lead Generation Specialist with at least 4 years of experience in B2B lead generation, outreach, and prospecting.",
  "The ideal candidate should have a deep understanding of LinkedIn Sales Navigator, personalized messaging strategies, and lead nurturing techniques to generate high-quality leads and drive business growth.",

  // Responsibilities
  "Identify and connect with potential clients and decision-makers through LinkedIn.",
  "Utilize LinkedIn Sales Navigator and automation tools to optimize outreach and lead generation.",
  "Craft personalized connection requests, follow-ups, and engagement strategies to convert prospects into leads.",
  "Conduct market research to identify target audiences and create prospect lists.",
  "Manage and optimize LinkedIn campaigns for lead generation and brand visibility.",
  "Collaborate with the sales and marketing teams to ensure lead qualification and conversion.",
  "Monitor and analyze campaign performance, response rates, and conversion metrics.",
  "Stay updated with LinkedIn algorithm changes and best practices for lead generation.",

  // Requirements
  "4+ years of proven experience in LinkedIn lead generation, B2B sales, or prospecting.",
  "Strong expertise in LinkedIn Sales Navigator, LinkedIn Ads, and outreach automation tools.",
  "Excellent communication and persuasive writing skills for effective cold outreach.",
  "Experience in lead qualification and CRM management (HubSpot, Salesforce, etc.).",
  "Ability to analyze data, track lead conversion rates, and optimize strategies accordingly.",
  "Strong understanding of B2B sales cycles and customer personas.",
  "Experience with email outreach and multi-channel lead generation is a plus."
];

export const ContentWriterJd = [
  "We are looking for a creative and detail-oriented Content Writer with at least 4 years of experience in crafting high-quality, engaging, and SEO-optimized content.",
  "The ideal candidate will have a strong understanding of content marketing strategies and the ability to create compelling content that drives traffic, engagement, and conversions.",

  // Responsibilities
  "Write, edit, and proofread high-quality content for blogs, websites, landing pages, social media, email campaigns, and marketing materials.",
  "Conduct in-depth research on industry-related topics to produce authoritative and informative content.",
  "Optimize content for SEO, ensuring proper keyword usage, readability, and search engine ranking improvements.",
  "Collaborate with marketing, design, and SEO teams to align content with brand goals and campaigns.",
  "Develop compelling headlines, CTAs, and storytelling elements to enhance engagement.",
  "Stay updated with industry trends, audience preferences, and content marketing best practices.",
  "Repurpose content for multiple platforms, including social media posts, infographics, and video scripts.",
  "Use content management systems (CMS) like WordPress to publish and format articles.",
  "Track content performance through Google Analytics, SEO tools, and engagement metrics.",

  // Requirements
  "4+ years of proven experience in content writing, copywriting, or content marketing.",
  "Exceptional writing, editing, and proofreading skills with a strong command of grammar.",
  "Experience with SEO best practices, keyword research, and on-page optimization.",
  "Ability to write in different tones and styles, adapting to brand voice.",
  "Familiarity with Google Docs, Grammarly, Surfer SEO, and CMS platforms like WordPress.",
  "Strong research skills and the ability to translate complex topics into reader-friendly content.",
  "Basic understanding of digital marketing and social media trends is a plus."
];

export const SEOSEMJd = [
  "We are seeking a highly skilled SEM Specialist with at least 4 years of experience to join our team.",
  "The ideal candidate will have a strong background in managing Google Ads, Bing Ads, and other PPC platforms, along with expertise in conversion rate optimization (CRO), campaign performance analysis, and budget management.",
  "You will play a key role in driving targeted traffic, optimizing ad spend, and ensuring maximum ROI for our digital campaigns.",

  // Responsibilities
  "Plan, execute, and optimize PPC campaigns across Google Ads, Bing Ads, and other platforms.",
  "Conduct keyword research, ad copy creation, A/B testing, and bid adjustments to improve campaign performance.",
  "Implement conversion rate optimization (CRO) strategies to maximize lead generation and sales.",
  "Analyze campaign performance metrics (CTR, CPC, CPA, ROAS) and generate actionable insights.",
  "Monitor and manage ad budgets to ensure cost efficiency and profitability.",
  "Collaborate with SEO and content teams to enhance paid and organic search synergy.",
  "Stay updated with SEM trends, Google algorithm changes, and ad platform updates.",
  "Utilize tools like Google Analytics, Google Tag Manager, and heatmaps to track user behavior and optimize conversions.",
  "Report on campaign performance, KPIs, and recommendations for continuous improvement.",

  // Requirements
  "4+ years of experience in SEM, PPC advertising, and conversion optimization.",
  "Proven expertise in managing Google Ads (Search, Display, YouTube), Bing Ads, and Shopping campaigns.",
  "Hands-on experience with Google Analytics, Google Tag Manager, and other tracking tools.",
  "Strong understanding of CRO techniques, A/B testing, and landing page optimization.",
  "Proficiency in Excel, Google Sheets, and data analysis tools.",
  "Experience with remarketing, audience targeting, and automation tools.",
  "Excellent analytical and problem-solving skills.",
  "Google Ads Certification is a plus."
];

export const UpworkBiddersJd = [
  "We are looking for an experienced Upwork Bidder / Business Development Executive to generate and close business opportunities on Upwork, Fiverr, Freelancer, and other freelancing platforms.",
  "The ideal candidate will have strong communication skills, experience in IT/software services, and a proven track record of winning projects.",

  // Responsibilities
  "Create and optimize Upwork, Fiverr, and Freelancer profiles to maximize visibility.",
  "Identify and bid on relevant projects across freelance platforms.",
  "Write customized, compelling proposals to win projects.",
  "Communicate with potential clients, negotiate deals, and close contracts.",
  "Collaborate with development and design teams to understand service offerings.",
  "Maintain relationships with existing clients for repeat business and upselling.",
  "Track and analyze bid success rates and improve bidding strategies.",
  "Stay updated with freelancing platform policies and best practices.",

  // Requirements
  "4+ years of experience in bidding and business development on Upwork or similar platforms.",
  "Strong knowledge of IT/software development services (e.g., web development, mobile apps, UI/UX, SEO).",
  "Excellent written and verbal communication skills in English.",
  "Ability to craft personalized proposals that stand out.",
  "Experience in lead generation, client handling, and sales negotiation.",
  "Understanding of pricing strategies and contract terms.",
  "Familiarity with CRM tools, spreadsheets, and reporting."
];

export const WordPressDeveloperJd = [
  "We are looking for an experienced WordPress Developer to build, customize, and optimize WordPress websites.",
  "The ideal candidate should have expertise in theme and plugin development, custom coding, and performance optimization.",
  "You will work closely with designers, marketers, and stakeholders to develop high-quality, scalable WordPress solutions.",

  // Responsibilities
  "Develop, customize, and maintain WordPress themes and plugins.",
  "Optimize website performance, security, and SEO.",
  "Implement custom functionalities using PHP, JavaScript, and WordPress hooks.",
  "Work with Advanced Custom Fields (ACF), Elementor, Gutenberg, and WooCommerce.",
  "Integrate third-party APIs, payment gateways, and CRM systems.",
  "Troubleshoot and fix WordPress-related issues and bugs.",
  "Ensure cross-browser compatibility and responsive design.",
  "Collaborate with designers and content teams to enhance UI/UX.",
  "Maintain version control using Git and manage deployments.",
  "Stay updated with WordPress core updates, security best practices, and industry trends.",

  // Requirements
  "4+ years of experience in WordPress development.",
  "Strong proficiency in PHP, JavaScript, HTML5, CSS3, and MySQL.",
  "Experience with custom theme and plugin development.",
  "Knowledge of REST API, GraphQL, and WordPress database structure.",
  "Experience with WooCommerce customization and integrations.",
  "Familiarity with SEO best practices and website performance optimization.",
  "Hands-on experience with page builders like Elementor, Divi, or WPBakery.",
  "Understanding of security hardening, SSL, and website backups."
];

export const ShopifyDeveloperJd = [
  "We are looking for an experienced Shopify Developer with expertise in building, customizing, and optimizing Shopify stores.",
  "The ideal candidate should have a strong understanding of Shopify Liquid, custom theme development, API integrations, and e-commerce best practices.",
  "You will work closely with designers, marketers, and project managers to deliver high-performing Shopify solutions.",

  // Responsibilities
  "Develop, customize, and optimize Shopify themes using Liquid, HTML, CSS, and JavaScript.",
  "Build and maintain custom Shopify apps and third-party integrations.",
  "Optimize Shopify stores for performance, SEO, and conversion rate.",
  "Implement custom functionalities using Shopify APIs, GraphQL, and RESTful services.",
  "Troubleshoot and debug Shopify-related issues.",
  "Integrate and configure payment gateways, shipping methods, and third-party apps.",
  "Ensure cross-browser compatibility and responsive design for mobile and desktop.",
  "Work with the marketing team to implement A/B testing, analytics tracking, and automation.",
  "Stay updated with Shopify updates, apps, and industry trends.",

  // Requirements
  "4+ years of experience in Shopify development.",
  "Strong expertise in Liquid, JavaScript (ES6+), HTML5, and CSS3 (SCSS/LESS).",
  "Experience with Shopify API, GraphQL, and webhooks.",
  "Hands-on experience with Shopify Plus, headless commerce, and custom app development.",
  "Knowledge of e-commerce best practices, SEO, and performance optimization.",
  "Experience with version control systems like Git.",
  "Familiarity with AJAX, jQuery, and third-party API integrations.",
  "Understanding of UX/UI design principles for e-commerce."
];

export const UiUxDesignerJd = [
  "We are seeking a UI/UX Designer with 4+ years of experience in designing intuitive, visually appealing, and user-friendly interfaces for web and mobile applications.",
  "The ideal candidate will have expertise in user research, wireframing, prototyping, and UI design using industry-standard tools.",
  "You will collaborate with developers, product managers, and stakeholders to create seamless digital experiences.",

  // Responsibilities
  "Conduct user research, usability testing, and competitor analysis to understand user needs.",
  "Design and create wireframes, prototypes, and high-fidelity UI designs.",
  "Develop and maintain design systems and UI component libraries.",
  "Work closely with developers to ensure accurate implementation of designs.",
  "Optimize UX for mobile, web, and cross-platform applications.",
  "Collaborate with product teams to align design with business goals.",
  "Ensure accessibility and responsiveness in UI design.",
  "Stay updated with UI/UX trends, best practices, and emerging design technologies.",

  // Requirements
  "4+ years of experience in UI/UX design.",
  "Proficiency in Figma, Adobe XD, Sketch, or other design tools.",
  "Strong knowledge of user-centered design principles, usability testing, and information architecture.",
  "Experience with interaction design, motion graphics, and micro-interactions.",
  "Basic understanding of HTML, CSS, and JavaScript for better developer collaboration.",
  "Ability to create user personas, customer journey maps, and UX flows.",
  "Strong portfolio showcasing UI/UX projects."
];

export const UnityGameDeveloperJd = [
  "We are looking for an experienced Unity Game App Developer to design, develop, and optimize interactive and engaging mobile and desktop games.",
  "The ideal candidate should have expertise in Unity 3D, C#, game physics, AI behavior, and multiplayer networking.",
  "You will work closely with designers, artists, and other developers to create high-quality gaming experiences.",

  // Responsibilities
  "Design, develop, and maintain 2D/3D games using Unity3D and C#.",
  "Optimize game performance for mobile (iOS/Android), PC, and consoles.",
  "Implement game mechanics, physics, and AI behaviors.",
  "Integrate third-party APIs, plugins, and SDKs for features like ads, analytics, and multiplayer.",
  "Collaborate with artists and designers to ensure smooth game development.",
  "Develop and implement UI/UX elements within Unity.",
  "Debug and resolve technical issues to improve game stability.",
  "Maintain version control using Git or Perforce.",

  // Requirements
  "4+ years of experience in Unity game development.",
  "Strong proficiency in C# and object-oriented programming.",
  "Experience with game physics, animations, shaders, and particle systems.",
  "Knowledge of multiplayer networking (Photon, Mirror, Unity Netcode).",
  "Familiarity with AR/VR development (ARKit, ARCore, Oculus, Vuforia).",
  "Experience with mobile game development and optimization techniques.",
  "Understanding of monetization strategies (in-app purchases, ads, subscriptions).",
  "Ability to profile and optimize games for high performance."
];

export const DevOpsDeveloperJd =  [
  "We are seeking an experienced DevOps Engineer to design, implement, and manage scalable infrastructure solutions.",
  "The ideal candidate will have expertise in CI/CD pipelines, cloud platforms (AWS, GCP, Azure), containerization, and automation tools.",
  "You will collaborate with development and operations teams to ensure high availability, performance, and security of applications.",

  // Responsibilities
  "Design and implement CI/CD pipelines to automate software deployment.",
  "Manage cloud infrastructure and services (AWS, GCP, or Azure).",
  "Deploy and manage containers using Docker and Kubernetes.",
  "Implement and maintain infrastructure as code (IaC) using Terraform or CloudFormation.",
  "Monitor system performance and troubleshoot infrastructure issues.",
  "Automate operational tasks using Bash, Python, or Ansible.",
  "Ensure security best practices in cloud and DevOps processes.",
  "Work with development teams to optimize application performance and scalability.",

  // Requirements
  "4+ years of experience in DevOps or cloud engineering.",
  "Strong expertise in AWS, GCP, or Azure cloud services.",
  "Hands-on experience with Docker, Kubernetes, and Helm.",
  "Proficiency in CI/CD tools like Jenkins, GitHub Actions, GitLab CI/CD, or CircleCI.",
  "Experience with infrastructure as code (IaC) tools like Terraform, CloudFormation, or Ansible.",
  "Strong scripting skills in Bash, Python, or Go.",
  "Knowledge of networking, load balancing, and security best practices.",
  "Experience with monitoring tools such as Prometheus, Grafana, ELK, or Datadog.",
  "Strong problem-solving skills and ability to work in a fast-paced environment."
];

export const PythonDeveloperJd = [
  "We are looking for an experienced Python Developer with a strong background in building scalable applications, APIs, and automation solutions.",
  "The ideal candidate should be proficient in Python frameworks such as Django or Flask, have experience with databases, and be comfortable working in cloud environments.",
  "You will be responsible for developing, optimizing, and maintaining high-performance applications that drive our business objectives.",

  // Responsibilities
  "Design, develop, and maintain Python-based applications.",
  "Build and optimize RESTful APIs using Django, Flask, or FastAPI.",
  "Develop and maintain efficient database structures (SQL/NoSQL).",
  "Write clean, maintainable, and well-documented code.",
  "Collaborate with front-end developers and other team members to integrate user-facing elements.",
  "Implement security and data protection best practices.",
  "Work with DevOps teams to deploy applications using Docker, Kubernetes, or CI/CD pipelines.",
  "Troubleshoot and debug applications to enhance performance and scalability.",
  "Stay updated with the latest trends and best practices in Python development.",

  // Requirements
  "4+ years of experience in Python development.",
  "Proficiency in Django, Flask, or FastAPI frameworks.",
  "Strong understanding of OOP, design patterns, and modular programming.",
  "Experience with SQL (PostgreSQL, MySQL) and NoSQL (MongoDB, Redis) databases.",
  "Familiarity with asynchronous programming and message queues (Celery, RabbitMQ, Kafka).",
  "Experience working with cloud services (AWS, GCP, Azure).",
  "Hands-on experience with Docker, Kubernetes, and CI/CD pipelines.",
  "Knowledge of unit testing, debugging, and performance tuning.",
  "Strong problem-solving skills and ability to work independently."
];

export const AiMlEngineerPkJd = [
  "We are seeking an experienced AI & Machine Learning Engineer to design, develop, and optimize AI-driven solutions.",
  "The ideal candidate will have strong expertise in machine learning, deep learning, and data science, with experience in developing and deploying AI models in production environments.",
  "You will work closely with data engineers, software developers, and business stakeholders to build intelligent systems that drive innovation.",

  // Responsibilities
  "Develop, train, and deploy machine learning and deep learning models for various applications.",
  "Research and implement cutting-edge AI algorithms to solve business challenges.",
  "Process and analyze large datasets to extract meaningful insights.",
  "Optimize model performance, scalability, and efficiency in production environments.",
  "Collaborate with data engineers to build robust data pipelines for AI applications.",
  "Develop APIs and integrate AI models into existing products and services.",
  "Continuously improve AI models by monitoring performance and retraining as needed.",
  "Stay updated with the latest advancements in AI/ML, frameworks, and industry trends.",

  // Requirements
  "4+ years of experience in machine learning, deep learning, and AI development.",
  "Bachelor's or Master’s degree in Computer Science, AI, Machine Learning, Data Science, or a related field.",
  "Strong programming skills in Python, R, or Java, with experience in ML frameworks like TensorFlow, PyTorch, or Scikit-learn.",
  "Experience with cloud-based AI solutions (AWS, GCP, Azure AI) and containerization tools (Docker, Kubernetes).",
  "Hands-on experience in NLP, Computer Vision, or Reinforcement Learning is a plus.",
  "Strong understanding of mathematical concepts like linear algebra, probability, and optimization."
];

export const ColdCallerJd = [
  "We are seeking a dynamic and results-driven Cold Calling Specialist with 4+ years of experience to join our sales team.",
  "The ideal candidate should have a strong background in cold calling, lead generation, and appointment setting.",

  // Responsibilities
  "Conduct outbound cold calls to potential clients in the IT industry.",
  "Generate and qualify leads, building a strong sales pipeline.",
  "Engage prospects, understand their business needs, and present suitable solutions.",
  "Schedule appointments and follow up with prospects to drive conversions.",
  "Maintain accurate records of interactions in the CRM system.",
  "Collaborate with the sales and marketing teams to optimize outreach strategies.",
  "Meet and exceed weekly/monthly call and conversion targets.",
  "Stay updated on industry trends and company offerings to deliver effective pitches.",

  // Requirements
  "4+ years of experience in cold calling, telemarketing, or sales.",
  "Proven track record of lead generation and appointment setting.",
  "Experience in the IT industry is a plus.",
  "Strong communication, persuasion, and negotiation skills.",
  "Ability to handle objections and maintain a positive attitude.",
  "Proficiency in CRM tools such as HubSpot, Salesforce, or similar.",
  "Self-motivated with the ability to work independently and in a team.",
  "Excellent time management and organizational skills.",

  // Preferred Skills
  "Experience in B2B sales and IT services.",
  "Familiarity with outbound sales strategies and tools.",
  "Prior experience working in a fast-paced sales environment."
];