import React, { useState, useEffect } from "react";
import "../hireform/hire-form.css";
import Progress from "../hireform/progress/Progress";
import RemoteTeam from "../hireform/remoteTeam/RemoteTeam";
import Skill from "../hireform/skillsFrom/Skill";
import TeamQty from "../hireform/teamQty/TeamQty";
import AboutHiringcandidate from "./abouthiringcandidate/AboutHiringCandidate";
import ThankYou from "../hireform/ThankYou";
import Header from "../hireform/Headerform";
import Scope from "../hireform/scopefile.js";
import JoinFormData from "../../Contants/JoinFormData.js";
import { Navigate } from "react-router-dom";
import UpdatedFooter from "../footer/UpdatedFooter.jsx";
import { AiOutlineClose } from "react-icons/ai";

const HireForm = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  const [step, setStep] = useState(0);
  const [error, setError] = useState(false);

  const [data, setData] = useState([]);
  const [showJd, setShowJd] = useState(false);
  const [jobDes, setJobDes] = useState([]);

  // const findskills = (id) => {
  //   const val = Scope.filter((t) => t.id === id);
  //   const dd = [...data, ...val[0].skills];
  //   const bb = dd.filter((value, index) => dd.indexOf(value) === index);
  //   setData(bb);
  // };
  // const delskills = (id) => {
  //   const val = Scope.filter((t) => t.id === id);
  //   const bb = data.filter((value) => !val[0].skills.includes(value));
  //   bb.length === 0 ? setData(data) : setData(bb);
  // };

  const findskills = (id) => {
    const val = JoinFormData.find((t) => t.id === id); // Use 'find' since we're only fetching one item
    setJobDes(val);
    if (val) {
      const uniqueSkills = [...new Set(val.skills)]; // Ensure skills are unique
      setData(uniqueSkills); // Replace data with the new skills only
    }
  };

  const delskills = (id) => {
    const val = JoinFormData.find((t) => t.id === id); // Use 'find' since we're only dealing with one item
    if (val) {
      const updatedData = data.filter((value) => !val.skills.includes(value));
      setData(updatedData); // Update data with skills removed
    }
  };

  const [selectedTeams, setSelectedTeams] = useState([]);
  const teams = [
    "React Js",
    "Vue Js",
    "Swift",
    "Python",
    "JavaScript",
    "Java",
    "wordpress",
    "Flutter",
    "Android",
    "Node Js",
    "PHP",
    "Bootstrap5",
    "React Native",
    "Rails",
    "Laravel",
    "Frontend",
    "QA",
    "Fullstack",
    "Backend",
  ];

  const [selectedSkills, setSelectedSkills] = useState([]);
  const skills = [
    "C#",
    "Aws",
    "Swift",
    "Html/Css",
    "Vue Js",
    "React Js",
    "JavaScript",
    "Java",
    "wordpress",
    "Flutter",
    "Android",
    "Node Js",
    "PHP",
    "Bootstrap5",
    "React Native",
    "MySQL",
    "MongoDB",
    "Rails",
    "Python",
    "Laravel",
  ];

  const qty = ["04", "05", "06", ">06"];
  const [selectedQty, setSelectedQty] = useState(0);
  const [errMsg, setErrMsg] = useState("");

  const [no, setNo] = useState("");

  // MY SECTION SETTING
  const [myform, setmyform] = useState({
    id: new Date().getTime().toString(),
    myformName: "",
    myformLastName: "",
    myformEmail: "",
    myformSkypeId: "",
    myformContact: "",
    myformAlternateContact: "",
    myformcompIndustry: "",
    myformcompLoc: "",
    myformcompEmp: "",
    myformGraduationyear: "",
    myformenglishcomlevel: "",
    myformclientcomhours: "",
    myformcandidatecountry: "",
    myformcandidatecity: "",
  });
  // END MY SECTION SETTING

  const [compAdd, setCompAdd] = useState({
    compName: "",
    compPhone: no,
    compEmail: "",
    compLoc: "",
    compEmps: "",
    compEngrs: "",
  });

  const [custAdd, setCustAdd] = useState({
    custName: "",
    custLoc: "",
    custEmail: "",
    custSkypeId: "",
  });

  const totalStep = 4;

  const clearData = () => {
    setSelectedTeams([]);
    setSelectedSkills([]);
    setSelectedQty(0);
    setNo("");
    setData([]);

    // MY SECTION SETTING
    setmyform({
      myformName: "",
      myformDesignation: "",
      myformEmail: "",
      myformSkypeId: "",
      myformcompName: "",
      myformcompIndustry: "",
      myformcompLoc: "",
      myformcompEmp: "",
    });
    // END MY SECTION SETTING

    setCompAdd({
      compEmail: "",
      compEmps: "",
      // compEngrs: '',
      compLoc: "",
      compName: "",
      compPhone: "",
    });
    setCustAdd({
      custEmail: "",
      custLoc: "",
      custName: "",
      custSkypeId: "",
    });
  };
  // const sheet = "hire team";

  // const sendForm = async () => {
  //   try {
  //     fetch(`https://sheetdb.io/api/v1/y0bbbe7x2bo52/?sheet=${sheet} `, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         data: [
  //           {
  //             skills: selectedSkills,
  //           },
  //         ],
  //       }),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => console.log(data));
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  // useEffect(() => {
  //   sendForm();
  // });

  const showErr = (msg) => {
    setErrMsg(msg);
    setError(true);
    setTimeout(() => {
      setError(false);
    }, 2000);
  };
  const stepChange = () => {
    if (step === 4) {
      return;
    }
    setStep((prev) => prev + 1);
  };

  const stepBack = () => {
    if (step === 0) {
      setStep(0);
      return;
    }
    setStep((prev) => prev - 1);
  };

  var percentage = ((step / totalStep) * 100).toFixed(0);
  percentage = percentage < 0 ? 0 : percentage;
  percentage = percentage > 100 ? 100 : percentage;
  return (
    <div className="container" style={{ position: "relative" }}>
      <Header />
      <div className="set-main-error">
        {error ? <p className="error-msg">{errMsg}</p> : ""}
      </div>
      <div className="formHeight  position-relative">
        <Progress progress={percentage} stp={step} />

        {step === 0 ? (
          <RemoteTeam
            next={stepChange}
            teams={teams}
            selectedTeams={selectedTeams}
            setSelectedTeams={setSelectedTeams}
            back={stepBack}
            err={showErr}
            Scope={JoinFormData}
            findskills={findskills}
            delskills={delskills}
            showJd={showJd}
            setShowJd={setShowJd}
          />
        ) : step === 1 ? (
          <Skill
            next={stepChange}
            skills={skills}
            selectedSkills={selectedSkills}
            setSelectedSkills={setSelectedSkills}
            back={stepBack}
            err={showErr}
            data={data}
            clearData={clearData}
          />
        ) : step === 2 ? (
          <TeamQty
            next={stepChange}
            qty={qty}
            selectedQty={selectedQty}
            setSelectedQty={setSelectedQty}
            back={stepBack}
            err={showErr}
          />
        ) : step === 3 ? (
          <AboutHiringcandidate
            val={no}
            setNo={setNo}
            compAdd={compAdd}
            custAdd={custAdd}
            setCompAdd={setCompAdd}
            myform={myform}
            setmyform={setmyform}
            next={stepChange}
            back={stepBack}
            err={showErr}
            selectedQty={selectedQty}
            selectedSkills={selectedSkills}
            selectedTeams={selectedTeams}
            clearData={clearData}
          />
        ) : (
          step === 4 && <ThankYou next={stepChange} />
        )}

        {/* {showJd && ( */}
        <div
          className={`jd-container ${showJd ? "slide-in" : "slide-out"}`}
          onAnimationEnd={() => {
            if (!showJd) setShowJd(false);
          }}
        >
          <div className="jd-container-header">
            <p>Job Description</p>
            <div style={{ cursor: "pointer" }} onClick={() => setShowJd(false)}>
              <AiOutlineClose />
            </div>
          </div>

          <div className="d-flex gap-2 ps-4" style={{marginBottom: jobDes?.location ? "0rem" : "1rem"}}>
            <p style={{ fontSize: "1rem", fontWeight: "700", marginBottom: "0rem" }}>Job Type: </p>
            <p className="mb-0">{jobDes.jobType}</p>
          </div>
          {jobDes?.location && (
            <div className="d-flex gap-2 ps-4">
              <p style={{ fontSize: "1rem", fontWeight: "700" }}>Location: </p>
              <p>{jobDes.location}</p>
            </div>
          )}

          <div className="jdDes-wrapper">
            <ul style={{ listStyle: "initial" }}>
              {jobDes && jobDes?.jobDes?.map((item, index) => <li>{item}</li>)}
            </ul>
          </div>
        </div>
        {/* )} */}
      </div>
      {/* <Footer2 faq={false} /> */}
      <UpdatedFooter />
    </div>
  );
};

export default HireForm;
